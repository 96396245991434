import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "react-lazy-load-image-component/src/effects/blur.css"

// import mouseWheel from "../images/mouse-wheel.gif"

import blob1 from "../images/blob1.svg"
import blob2 from "../images/blob2.svg"
import blob3 from "../images/blob3.svg"

const LoanPrograms = () => (
  <Layout>
    <SEO title="Loan Programs" />
    {/* <div className="container-fluid hero-section">
      <div className="row">
        <div className="col-md-5 vertical-align d-none-mobile">
          <div className="brandstamp">
            <h1>Our Programs</h1>
          </div>
        </div>
        <div className="col-md-7 lprog-hero-img vertical-align">
          <div className="brandstamp-mobile text-white">
            <h1>Our Programs</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-12 text-center scroll-down">
      <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
    </div> */}

    <div style={{ padding: "5rem 0" }}>
      <div className="container loan-programs-wrapper">
        <div className="row">
          <div className="col-lg-4">
            <Link to="/loan-programs/va-home-loans" className="lprog-wrapper">
              <div
                className="card apply-container p-0 mt-5"
                style={{ border: "none" }}
              >
                <div className="lprog-cardimg-1" />
                <div className="card-body">
                  <h5 className="card-title mt-0">VA Home Loans</h5>
                  <p className="card-text">
                    This loan offers 100% financing to buyers of Active Military
                    Duty and honorably discharged Veterans.
                  </p>
                  <div className="text-right">
                    <span to="/loan-programs/va-home-loans">Learn More</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link
              to="/loan-programs/exclusive-high-balance-nationwide"
              className="lprog-wrapper"
            >
              <div
                className="card apply-container p-0 mt-5"
                style={{ border: "none" }}
              >
                <div className="lprog-cardimg-2" />
                <div className="card-body">
                  <h5 className="card-title mt-0">Colorado High Balance</h5>
                  <p className="card-text">
                    Mission Mortgage Colorado offers an Exclusive Conventional
                    High Balance loan program - up to $726,525 in every County,
                    regardless of County Loan Limit.
                  </p>
                  <div className="text-right">
                    <span to="/loan-programs/exclusive-high-balance-nationwide">
                      Learn More
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/loan-programs/jumbo-loans" className="lprog-wrapper">
              <div
                className="card apply-container p-0 mt-5"
                style={{ border: "none" }}
              >
                <div className="lprog-cardimg-3" />
                <div className="card-body">
                  <h5 className="card-title mt-0">JUMBO Loans</h5>
                  <p className="card-text">
                    Big loan? No problem. Our unique JUMBO programs might be the
                    perfect fit.
                  </p>
                  <div className="text-right">
                    <span to="/loan-programs/jumbo-loans">Learn More</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/loan-programs/doctor-program" className="lprog-wrapper">
              <div
                className="card apply-container mt-5 p-0"
                style={{ border: "none" }}
              >
                <div className="lprog-cardimg-4" />
                <div className="card-body">
                  <h5 className="card-title mt-0">Doctor Program</h5>
                  <p className="card-text">
                    A special loan program for doctors and medical students with
                    student loans.
                  </p>
                  <div className="text-right">
                    <span to="/loan-programs/doctor-program">Learn More</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/loan-programs/usda-financing" className="lprog-wrapper">
              <div
                className="card apply-container mt-5 p-0"
                style={{ border: "none" }}
              >
                <div className="lprog-cardimg-5" />
                <div className="card-body">
                  <h5 className="card-title mt-0">USDA Financing</h5>
                  <p className="card-text">
                    A USDA Home Loan is a mortgage loan offered to rural
                    property owners by the United States Department of
                    Agriculture.
                  </p>
                  <div className="text-right">
                    <span to="/loan-programs/usda-financing">Learn More</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/loan-programs/fha-home-loans" className="lprog-wrapper">
              <div
                className="card apply-container mt-5 p-0"
                style={{ border: "none" }}
              >
                <div className="lprog-cardimg-6" />
                <div className="card-body">
                  <h5 className="card-title mt-0">FHA Home Loans</h5>
                  <p className="card-text">
                    FHA loans offer more flexibility than a conventional loan,
                    and are a great option for those looking for downpayment
                    assistance.
                  </p>
                  <div className="text-right">
                    <span to="/loan-programs/fha-home-loans">Learn More</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="container svc-section">
      <h2 className="text-center">We do things differently:</h2>
      <p className="text-center mb-5">
        There are a number of ways we make your life easier. Here are just a
        few:
        <br />
      </p>
      <div className="row pt-4 pb-5 align-items-center">
        <div className="col-md-6 p-relative">
          <div className="svc-container svc-img-1"></div>
          <div className="blob1">
            <img src={blob1} alt="blob 1" />
          </div>
        </div>
        <div className="col-md-6 vertical-align">
          <div className="svc-content">
            <h4 className="mb-2">
              <strong>Elite Mortgage Insurance</strong>
              <b />
            </h4>
            <p className="mb-3">
              For those putting less than 20% down, Mission Mortgage Colorado
              offers some of the lowest Mortgage Insurance rates available
              anywhere!
              <br />
            </p>
            <h5>
              <strong>KEY FEATURES</strong>
              <br />
            </h5>
            <ul>
              <li>
                Starts at 640 credit scrore
                <br />
              </li>
              <li>
                Among the lowest mortgage insurance rates in the industry
                <br />
              </li>
              <li>
                Instant Mortgage Insurance Certificate - saves 3-7 days on the
                loan process
                <br />
              </li>
              <li>
                None of the mortgage insurance underwriting overlays that other
                companies have (debt-to-income, number of borrowers...)
                <br />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row pt-5 pb-5 flex-column-reverse flex-md-row align-items-center">
        <div className="col-md-6 vertical-align">
          <div className="svc-content">
            <h4 className="mb-2">
              <strong>
                Free Escrow Waivers On Conventional Loans -- up to 95% LTV
              </strong>
              <br />
            </h4>
            <p className="mb-3">
              Escrow waivers up to 95% LTV is unheard of, you won't find this
              anywhere else.&nbsp;AND we don't charge the fees commonly
              associated with waiving escrows - it's free!
              <br />
            </p>
            <h5>
              <strong>KEY FEATURES</strong>
              <br />
            </h5>
            <ul>
              <li>
                Escrow waivers up to 95% LTV
                <br />
              </li>
              <li>
                No escrow waiver fee
                <br />
              </li>
              <li>
                Eliminates initial escrow deposit
                <br />
              </li>
              <li>
                Available on all conventional loans
                <br />
              </li>
              <li>
                Option to waive just taxes, just insurance or both
                <br />
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="svc-container svc-img-2"></div>
          <div className="blob2">
            <img src={blob2} alt="blob 2" />
          </div>
        </div>
      </div>
      <div className="row pt-5 pb-5 align-items-center">
        <div className="col-md-6">
          <div className="svc-container svc-img-3 "></div>
          <div className="blob3">
            <img src={blob3} alt="blob 3" />
          </div>
        </div>
        <div className="col-md-6 vertical-align">
          <div className="svc-content">
            <h4 className="mb-2">
              <strong>VIRTUAL E-CLOSING</strong>
              <br />
            </h4>
            <p className="mb-2">
              You have the option to close when you want, where you want,
              completely online -- from the comfort of your living room, while
              celebrating at your favorite restaurant, or anywhere with Wi-Fi
              access.
              <br />
            </p>
            <p className="mb-3">
              Our option of a virtual E-close allows you to close without
              wet-signing a single document by verifying your ID with a notary
              through video chat.
              <br />
            </p>
            <h5>
              <strong>KEY FEATURES</strong>
              <br />
            </h5>
            <ul>
              <li>
                Faster, more convenient closing process
                <br />
              </li>
              <li>
                E-sign all documents, including the mortgage promissory note,
                for anywhere that has Wi-Fi access
                <br />
              </li>
              <li>
                No in-person signing needed
                <br />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row pt-5 pb-5 flex-column-reverse flex-md-row align-items-center">
        <div className="col-md-6 vertical-align">
          <div className="svc-content">
            <h4 className="mb-2">
              <strong>DOC-LESS INCOME AND ASSETS</strong>
              <br />
            </h4>
            <p className="mb-3">
              We're revolutionizing the mortgage industry yet again by combining
              our E-sign technology with systems that securely and automatically
              verify income, assets, and tax returns.
              <br />
            </p>
            <h5>
              <strong>KEY FEATURES</strong>
              <br />
            </h5>
            <ul>
              <li>
                No more bank statements
                <br />
              </li>
              <li>
                No more pay stubs
                <br />
              </li>
              <li>
                No more tax returns
                <br />
              </li>
              <li>
                Just E-sign and go
                <br />
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="svc-container svc-img-4 p-relative"></div>
          <div className="blob1">
            <img src={blob1} alt="blob 1" />
          </div>
        </div>
      </div>
      <div className="row pt-5 pb-5 align-center">
        <div className="col-md-6">
          <div className="svc-container svc-img-5"></div>
          <div className="blob3">
            <img src={blob3} alt="blob 3" />
          </div>
        </div>
        <div className="col-md-6 vertical-align">
          <div className="svc-content">
            <h4 className="mt-0 mb-2">
              <strong>FLEX TERM FINANCING</strong>
              <br />
            </h4>
            <p className="mb-3">
              Customize the perfect loan without resetting the mortgage clock!
              Flex Term allows you to choose your own amortization term!&nbsp;
              <br />
            </p>
            <h5>
              <strong>KEY FEATURES</strong>
              <br />
            </h5>
            <ul>
              <li>
                Available on all conventional loan products
                <br />
              </li>
              <li>
                Terms available from 8–30 years
                <br />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="apply-section">
      <div className="protruded-gradient"></div>
      <div className="container">
        <div className="apply-container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <div>
                <h2 className="mb-2 mt-3">
                  Let's get started
                  <br />
                </h2>
                <p className="pb-4">
                  Getting pre-approved can be quick and easy. Start the process
                  today!
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col">
                  <a
                    href="https://plus.preapp1003.com/Darlene-Franklin"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-global w-100 mb-3"
                  >
                    Apply Now
                  </a>
                </div>
                <div className="col">
                  <Link
                    to="/loan-process"
                    className="btn-global-inverted w-100 text-center"
                    style={{ padding: "10px 20px" }}
                  >
                    Our Process
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default LoanPrograms
